<template>
    <div class="card">
        <div class="card-header">
            <i class="icon-list"></i>
            Agendador de Rotinas
            <button style="margin-bottom: 1%;" class="btn btn-primary float-right" data-toggle="modal" data-target=".bd-example-modal-lg" v-on:click="pegarDados()">
                <i class="fa fa-plus"></i>
                Novo
            </button>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <select name="paginas" id="paginas" class="custom-select" v-model="quantidadePage">
                            <option value="10" selected>Mostrar 10 itens</option>
                            <option value="20">Mostrar 20 itens</option>
                            <option value="30">Mostrar 30 itens</option>
                            <option value="40">Mostrar 40 itens</option>
                            <option value="50">Mostrar 50 itens</option>
                        </select>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="input-group mb-2">
                            <input type="text" class="form-control" id="inlineFormInputGroup" v-model="pesquisaElemento" v-on:keyup.enter="filtraCron">
                            <i style="display:none;" @click="limparFiltro" class="fa fa-times fa-lg icone-x" id="icone-x"></i>
                            <div class="input-group-prepend">
                                <button class="btn btn-primary" v-on:click="filtraCron">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 5%;" class="col-lg-12 col-md-12 col-sm-12">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th @click="sort('id_cron')">
                                        ID
                                        <i class="fa fa-sort" aria-hidden="true"></i>
                                    </th>
                                    <th @click="sort('nome')">
                                        NOME
                                        <i class="fa fa-sort" aria-hidden="true"></i>
                                    </th>
                                    <th @click="sort('bo_ativo')">
                                        STATUS
                                        <i class="fa fa-sort" aria-hidden="true"></i>
                                    </th>
                                    <th class="text-center"> 
                                        <i class="fa fa-gears"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(l, index) in sortedColumns" :key="index">
                                    <td class="text-center">{{l.id_cron}}</td>
                                    <td class="text-center">{{l.nome}}</td>
                                    <td class="text-center">
                                        <span v-if="l.bo_ativo == 'S'" class="badge badge-ativo">Ativo</span>
                                        <span v-else class="badge badge-inativo">Inativo</span>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-example-modal-lg" v-on:click="pegarDados(l.id_cron)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalCronNovo">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title text-center"> Dados do cron</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="table-responsive">
                                            <form action="">
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <div class="col-lg-2 col-md-2 col-sm-12 formata_div">
                                                        <label for="nome_cron">Id:</label>
                                                        <input class="form-control" type="text" name="id_cron" id="id_cron" disabled v-model="dateToCronId.id_cron">
                                                    </div>
                                                    <div class="col-lg-5 col-md-5 col-sm-12 formata_div">
                                                        <label for="nome_cron">Nome: <span class="obrigatorio">*</span></label>
                                                        <input class="form-control" type="text" name="nome_cron" id="nome_cron" v-model="dateToCronId.nome" :disabled="dateToCronId.bo_ativo">
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 formata_div">
                                                        <label for="nome_cron">Última execução:</label>
                                                        <input class="form-control" type="date" name="dt_execucao_cron" id="dt_execucao_cron" disabled v-model="dateToCronId.dt_execucao">
                                                    </div>
                                                    <div class="col-lg-1 col-md-1 col-sm-6 formata_div">
                                                        <label for="nome_cron">Ativo:</label>
                                                        <input type="checkbox" name="reprocessar" style="margin-left: 12px;" v-model="dateToCronId.bo_ativo">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <label for="desc_cron">Descrição:<span class="obrigatorio">*</span></label>
                                                        <textarea class="form-control" rows="3" v-model="dateToCronId.desc_cron"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <label for="url_cron">Url a ser chamada:<span class="obrigatorio">*</span></label>
                                                        <input type="text" name="url_cron" id="url_cron" class="form-control" v-model="dateToCronId.url" :disabled="dateToCronId.bo_ativo">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <div class="col-lg-4 col-md-4 col-sm-6 formata_div">
                                                        <label for="intervalo_cron">Intervalo de execução:</label>
                                                        <div class="formata_div">
                                                            <input type="number" name="intervalo" id="intervalo" class="col-lg-4 col-md-4 col-sm-6 form-control formata_div" v-model="dateToCronId.intervalo" :disabled="dateToCronId.bo_ativo">
                                                            <select class="col-lg-8 col-md-8 col-sm-6 form-control formata_div" v-model="dateToCronId.periodo" :disabled="dateToCronId.bo_ativo">
                                                                <option value="MINUTOS"> Minutos </option>
                                                                <option value="HORAS"> Horas </option>
                                                                <option value="DIAS"> Dias </option>
                                                                <option value="SEMANAS"> Semanas </option>
                                                                <option value="MESES"> Meses </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-5 col-sm-10 formata_div">
                                                        <label class="control-label tamanhoFont"> 
                                                            Próxima Execução
                                                        </label>
                                                        <div class="formata_div">
                                                            <input class="col-lg-7 col-md-7 col-sm-6 form-date form-control formata_div" type="date" v-model="dateToCronId.dt_proxima_execucao" :disabled="dateToCronId.bo_ativo">
                                                            <input class="col-lg-5 col-md-5 col-sm-6 form-time border-date-right form-control formata_div" type="time" v-model="dateToCronId.hr_proxima_execucao" :disabled="dateToCronId.bo_ativo">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-2 marginTop formata_div">
                                                        <label for="nome_cron">Manter horário fixo:</label>
                                                        <input type="checkbox" name="reprocessar" style="margin-left: 6px;" v-model="dateToCronId.bo_horario_fixo" :disabled="dateToCronId.bo_ativo">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <div class="col-lg-4 col-md-6 col-sm-6 formata_div">
                                                        <label for="palavra_chave_token">Token:</label>
                                                        <input :type="mostrarSenha ? 'text' : 'password'" @change="exibirSenha" name="token_cron" id="token_cron" class="form-control" v-model="dateToCronId.chave_token" autocomplete="off" style="position: relative;">
                                                    </div>
                                                    <i class="fa fa-eye-slash" v-if="!mostrarSenha" id="togglePassword" @click="exibirSenha"></i>
                                                    <i class="fa fa-eye" v-else id="togglePassword" @click="exibirSenha"></i>
                                                    <div class="col-lg-5 col-md-6 col-sm-6 formata_div">
                                                        <label for="palavra_chave_token">Função do e-mail:<span class="obrigatorio">*</span></label>
                                                        <input type="text" name="funcao_email" id="funcao_email" class="form-control" v-model="dateToCronId.funcao">
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-4 formata_div">
                                                        <label for="nome_cron">Dispara e-mail:</label>
                                                        <input type="checkbox" name="dispara_email" style="margin-left: 21px;" v-model="dateToCronId.bo_dispara_email">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12 margem_inferior">
                                                    <label for="destinatario" class="col-lg-12 col-md-12 col-sm-12">Destinatário:</label>
                                                    <div class="col-lg-4 col-md-4 col-sm-12 formata_div">
                                                        <label for="insere_email">Nome:</label>
                                                        <input type="text" name="nome_email" id="nome_email" class="form-control" v-model="dateToCronId.insereNomeEmail">
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 formata_div">
                                                        <label for="insere_email">E-mail:</label>
                                                        <input type="text" name="email" id="email" class="form-control" v-model="dateToCronId.insereEmail">
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 formata_div">
                                                        <button class="btn btn-primary floatRight marginTop1_5Em" type="button" @click="novoEmail(dateToCronId.id_cron)"> Adicionar </button>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th> Nome </th>
                                                                <th> E-mail </th>
                                                                <th>  </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="em in listaEmail" :key="em.id_email_rotina">
                                                                <td class="text-center"> {{em.nome_pessoa}} </td>
                                                                <td class="text-center limiteLinha"> {{em.email}} </td>
                                                                <td class="text-center">
                                                                    <button class="btn btn-danger btn-sm" @click="inativaEmail(em.id_email_rotina)">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="col-sm-12 col-md-12 text-right col-lg-12 paddingLeft_0 paddingRight_0 marginRight_0">
                                            <button class="btn btn-success espaco_botao_inferior" type="button" @click="executeCron(dateToCronId.id_cron)"> Executar </button>
                                            <button class="btn btn-primary espaco_botao_inferior" type="button" @click="postNewCron()"> Salvar </button>
                                            <button class="btn btn-default borda_botao_cancelar" type="button" data-dismiss="modal" aria-label="Close"> Cancelar </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-row v-if="this.cronList.length > 0">
                            <b-col>
                                Pagina {{ this.currentPage }} de {{ this.totalPages }}, resultado
                                {{ this.quantidadeResults }} de {{ this.totalResults }}
                            </b-col>
                            <b-col>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="this.totalResults"
                                    :per-page="this.quantidadePage"
                                    class="mt-4">
                                    <template v-slot:first-text>
                                        <span class="text-primary">
                                        <i class="fa fa-step-backward"></i>
                                        </span>
                                    </template>
                                    <template v-slot:prev-text @click="prevPage">
                                        <span class="text-primary">
                                        <i class="fa fa-chevron-left"></i>
                                        </span>
                                    </template>
                                    <template v-slot:next-text @click="nextPage">
                                        <span class="text-primary">
                                        <i class="fa fa-chevron-right"></i>
                                        </span>
                                    </template>
                                    <template v-slot:last-text>
                                        <span class="text-primary">
                                        <i class="fa fa-step-forward"></i>
                                        </span>
                                    </template>
                                    <template v-slot:ellipsis-text>
                                        <span>
                                        <i class="fa fa-ellipsis-h"></i>
                                        </span>
                                    </template>
                                    <template v-slot:page="{ page, active }">
                                        <b v-if="active">{{ page }}</b>
                                        <i v-else>{{ page }}</i>
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                        <b-row v-else style="display:flex; justify-content:center;">
                            <strong>{{listaVaziaMsg}}</strong>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .badge-ativo {
        color: #fff;
        background-color: green;
        width: 39px;
    }

    .badge-inativo {
        color: #fff;
        background-color: red;
    }

    .formata_div {
        display:inline-block;
    }

    .margem_inferior {
        margin-bottom: 15px;
    }

    .borda_botao_cancelar {
        border-color: black;
    }
    
    .espaco_botao_inferior {
        margin-right: 6px;
    }

    #togglePassword {
        position: absolute;
        left: 29%;
        top: 40px;
    }

    .obrigatorio{
        color: red;
    }

    .formata_div label {
        font-weight: bold;
    }
</style>

<script>
import CronService from "../../../services/cron";
import 'bootstrap/dist/js/bootstrap.min.js';

export default {
  name: "Cron",
  data() {
    return {
        cronList:[],
        dateToCronId:{},
        page : 1,
        pageSize: 10,
        currentPage: 1,
        quantidadePage: 10,
        totalPages: 0,
        totalResults: 0,
        quantidadeResults: 0,
        pesquisaElemento: '',
        listaVaziaMsg: 'Buscando informações',
        listaEmail: [],
        mostrarSenha: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
        CronService.getAllList()
              .then(response => {
                this.cronList = response.data.data;
              });
    },
    limparFiltro(){
        location.reload();
    },
    montarFiltro(value)
    {
        let filtroTexto    = this.pesquisaElemento.toUpperCase();

        let exibeRegistro = String(value.id_cron).toUpperCase().includes(filtroTexto) 
                        || String(value.nome).toUpperCase().includes(filtroTexto) 
                        || String(value.bo_status).toUpperCase().includes(filtroTexto)
        return exibeRegistro;
    },
    nextPage() {
        if((this.currentPage*this.pageSize) < this.cronList.length) {
            this.currentPage++;
        }
    },
    prevPage() {
        if(this.currentPage > 1) {
            this.currentPage--;
        }
    },
    pegarDados(id) {
        
        if(id == null) {
            this.dateToCronId = {};
            this.listaEmail = [];
        } else {
            CronService.getOneCronDate(id)
                    .then(response => {
                            this.dateToCronId = response.data.data[0];

                            let rotina = '';

                            if(this.dateToCronId.email[0]) {
                                rotina = this.dateToCronId.email[0].rotina;
                            } else {
                                rotina = '';
                            }
                            
                            if(this.dateToCronId.bo_ativo == 'S') {
                                this.dateToCronId.bo_ativo = true;
                            } else {
                                this.dateToCronId.bo_ativo = false;
                            }

                            if(this.dateToCronId.bo_horario_fixo == 'S') {
                                this.dateToCronId.bo_horario_fixo = true;
                            } else {
                                this.dateToCronId.bo_horario_fixo = false;
                            }

                            if(this.dateToCronId.bo_dispara_email == 'S') {
                                this.dateToCronId.bo_dispara_email = true;
                            } else {
                                this.dateToCronId.bo_dispara_email = false;
                            }

                            this.dateToCronId.funcao = rotina;

                            this.listaEmail = this.dateToCronId.email;
                    });
        }
    },
    sort(s) {
        if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
        }
        let dir = this.currentSortDir;
        this.currentSort = s;
        this.cronList.sort(function(a,b){
            let modifier = 1;

            if(dir === 'desc') {
                modifier = -1;
            }

            if(a[s] < b[s]) {
                return -1 * modifier;
            }

            if(a[s] > b[s]) {
                return 1 * modifier;
            }
            
            return 0;
        });
    },
    filtraCron() {
        return this.cronList.filter(this.montarFiltro);
    },
    postNewCron() {
        let data = this.dateToCronId;
            
        let valida = this.validate();

        if(valida) {
            if(this.dateToCronId.id_cron == null) {
                this.dateToCronId.email = this.listaEmail;

                CronService.criarCron(JSON.stringify(data))
                                 .then(
                                        this.$helper.showMsg("Cron salvo com sucesso!", "success"),
                                        document.getElementById('close').click(),
                                        location.reload()
                                      );
            } else {
                CronService.editarCron(JSON.stringify(data))
                                 .then(
                                        this.$helper.showMsg("Cron editado com sucesso!", "success"),
                                        document.getElementById('close').click(),
                                        this.getList()
                                      );
            }
        }
    },
    novoEmail(id){
        let dataEmail = {
            'nome_pessoa': this.dateToCronId.insereNomeEmail,
            'email': this.dateToCronId.insereEmail,
            'rotina': this.dateToCronId.funcao,
            'id_cronjob': id 
        }

        this.listaEmail.push(dataEmail);
        this.dateToCronId.insereNomeEmail = "";
        this.dateToCronId.insereEmail = "";

    },
    inativaEmail(id) {
        var listaPercorrer = this.listaEmail;
        for(let i=0; i<listaPercorrer.length; i++) {
            if(listaPercorrer[i].id_email_rotina == id) {
                this.listaEmail.splice(i, 1);
            }
        }
    },
    executeCron(id) {
        CronService.executarCron(id)
                    .then(function(response){
                        if(response.status == 200) {
                            this.$helper.showMsg("Rotina Executada com Sucesso", "success");
                            document.getElementById('close').click();
                        }
                    })
                    .catch(function(){
                        this.$helper.showMsg("Erro ao executar rotina", "error");
                    });
    },
    exibirSenha() {
        this.mostrarSenha =! this.mostrarSenha;
    },
    validate() {
        if(this.dateToCronId.nome == null || this.dateToCronId.nome == "") {
            this.$helper.showMsg("O nome do cron precisa ser preenchido.", "error");
            return false;
        }

        if(this.dateToCronId.desc_cron == null || this.dateToCronId.desc_cron == "") {
            this.$helper.showMsg("A descrição do cron precisa ser preenchido.", "error");
            return false;
        }

        if(this.dateToCronId.url == null || this.dateToCronId.url == "") {
            this.$helper.showMsg("A url a ser chamada deve ser preenchida.", "error");
            return false;
        }

        if(this.dateToCronId.bo_dispara_email) {
            if(this.dateToCronId.funcao == null || this.dateToCronId.funcao == "") {
                this.$helper.showMsg("O nome da função deve ser preenchido.", "error");
                return false;
            }

            if(this.listaEmail.length <= 0) {
                this.$helper.showMsg("É necessário ao menos 1 e-mail cadastrado.", "error");
                return false;
            }
        }

        return true;
    }
  },
  computed: {
    sortedColumns() {
        let lista = this.cronList;
        
        this.pageSize = this.quantidadePage;
        this.totalResults = Object.values(lista).length;
        
        if(this.totalResults > this.quantidadePage) {
            this.totalPages = Math.ceil(this.totalResults/this.quantidadePage);
            if(this.currentPage == this.totalPages){
                this.quantidadeResults = this.totalResults;
            }else{
                this.quantidadeResults = this.quantidadePage* this.currentPage;
            }
        } else {
            this.quantidadeResults = this.totalResults;
            this.totalPages = 1;
        }
        
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        
        return lista.slice(start, end);
    }
  }
}; 
</script>