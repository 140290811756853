import { http } from './config'

export default {
    getAllList: () => {
        return http.get('cron/listar-cron');
    },
    getOneCronDate:(id) => {
        return http.get('cron/date-cron/'+id);
    },
    criarCron:(data) => {
        return http.post('cron/criar-cron', data);
    },
    editarCron:(data) => {
        return http.put('cron/editar-cron', data);
    },
    executarCron:(id) => {
        return http.get('cron/executa-cron/'+id);
    }
}